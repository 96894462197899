.tech-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    background-color: #2E2E2E;
}


  
.tech-container .main-heading {
    font-size: 30px;
    margin-bottom: 30px;
}

.technologies {
    justify-content: center; 
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 100px;
    max-width: 100%;
}



/* For Mobile Devices */
@media (max-width: 991px) {
  .aboutImage{
    width: 95vw;
    padding: 3rem 0;
  }
}
  
  
