#logo img{
  width: 170px !important;
  padding: 20px 0px;
}
.section-footer {
  padding-top: 40px;
  background-color: #171717;
}
.container-row{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  place-items: center;
  padding: 25px;
}
.main-heading{
  margin-top: 10px;
  font-size: large;
  color: #fff;
}

.main-heading #logo{
  color:#fff;
  font-size: large;
  text-decoration: none;
}
.color-b{
  color: #00BFFF	;
}
.color-w{
  color: #f3f3f3;
  font-size: 15px;
}
.section-footer ul {
  line-height: 2.6;
  list-style: none;
  padding: 0;
}
.section-footer .item-list-a a {
  font-size: 18px;
  padding-right: 4px;
  list-style: none;
  color: #f3f3f3;
  text-decoration: none;
}
.item-list-a a:hover{
  color: #9c9c9c  !important;
  cursor: pointer;
}
.section-footer h3{
  margin-bottom: 1rem;
}

footer {
  background-color: #171717;
  text-align: center;
  padding: 0;
}
.socials-a {
  display: flex;
  justify-content: center;
}
.socials-a ul {
  list-style: none;
  padding: 0;
  display: flex;
}
.socials-a li {
  margin: 20px 10px;
}
.socials-a a {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: #007bff;
  color: #fff; 
  font-size: 24px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  text-decoration: none;
}
.socials-a a:hover {
  background-color: #0056b3;
}
.copyright-footer {
  border-top: 0.5px solid #ddd;
  padding-top: 6px;
}




/* Responsive styles for smaller screens (e.g., mobile) */
@media screen and (max-width: 767px) {
  .container-row {
    flex-direction: column;
    padding: 10px;
    text-align: center;
  }
  .main-heading{
    font-size: 25px;
    padding-top: 12px;
  }
  .section-footer .item-list-a a{
    font-size: 14px;
    /* color: #f3f3f3; */
  }
  .w-text-a{
    font-size: 14px;
    padding-top: 5px;
  }
  .color-w .color-b{
    font-size: 14px;
    padding-top: 5px;
  }
}


.payment-option{
  line-height: 1.5rem;
}