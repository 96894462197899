.team-section {
    text-align: center;
    background-color: #2E2E2E;
    padding: 50px 0;
}
.team-h {
    color: #fff;
    margin-bottom: 30px;
}
.team-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    max-width: 1000px;
    margin: 0 auto;
}
.team {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-self: center;
    padding: 20px;
    width: 30%; 
}

.team img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 10px;
}

.team h3 {
    font-size: 20px;
    padding-bottom: 0;
}

.team p {
    font-size: 14px;
    line-height: 1.5;
}
