.contact-container {
    padding: 70px;
}

.formBg {
    border-radius: 30px;
    padding: 40px ;
    background: rgb(0, 0, 0);
    background: linear-gradient(315deg, rgba(0, 0, 0, 0.747) 0%, rgb(41, 41, 41) 100%);}

.contact-input {
    border: 1px solid #494848;
    background: #1d1d1d;
    /* background: #2E2E2E; */
    color: rgba(255, 255, 255, 0.808);
}

.contact-input::placeholder {
    color: rgba(255, 255, 255, 0.753);
}

.contact-input:focus,
.contact-input:active {
    background: rgb(0, 191, 255);
    background: linear-gradient(90deg, rgba(0, 191, 255, 0) 0%, rgba(255, 255, 255, 0.219) 100%);
    color: white;
}

.submitBtn{
    padding: 10px 30px;
    /* width: 30%; */
    color: white;
    border:2px solid  #00BFFF;
    background-color: transparent;
    transition: all ease .3s;
}
.submitBtn:hover{
    color: black;
    background-color: #00BFFF;
}
.contact-intro {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.h-contact {
    margin-top: 60px;
    color: white;
    font-size: 35px;
}

@media (max-width:768px){
    .contact-container{
        padding: 0;
    }
    .formBg{
        padding: 40px 10px !important;
    }
}


