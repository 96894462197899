.services-section {
    background-color: #2E2E2E;
    padding: 70px;
}

.topTalent {
    padding-left: 40%;
}

.card{
    background-color: #2E2E2E;
    height: 350px;
    cursor: pointer;
    overflow: hidden;
}
.card:hover{
    background-color: #0B7DA4;
    transition: all ease .3s;
    transform: scale(1.02);
}
.card:hover h3,
.card:hover .service-1 p{
    color: black;
}


.slideSkillImages{
    width: 80px;
    border-radius: 50%;
}


.services-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    max-width: 1000px;
    margin: 0 auto;
}

.service-1 {
    display: flex;
    flex-direction: column;
    flex-basis: calc(50% - 20px);
    text-align: left;
    margin-bottom: 20px;
    border-radius: 4px;
    padding: 20px;
}

.service-up {
    display: flex;
    align-items: center;
}

.service-up svg {
    font-size: 3.5rem !important;
    color: white;
}

.card:hover .service-up svg{
    color: black;
}

.service-1 h3 {
    font-size: 20px;
    margin-bottom: 6px;
    margin-left: 12px;
}

.service-1 p {
    font-size: 14px;
    line-height: 1.5;
}
@media (max-width:991px){

    .services-section{
    background-color: #2E2E2E;

        padding:  25px;
    }
    
}

@media (max-width:700px){

  .TeamImage{
    width: 350px;
  }
  .slideSkillImages{
    width: 60px !important;
    border-radius: 50%;
}
    
}

